import { CountUp } from 'countup.js/dist/countUp.umd.js'
import AdobeCustomTracking from '~/helpers/mixins/adobe-custom-tracking'

const toplistAnimatedEls = document.querySelectorAll('.new-animated-card')
const toplistBonusEls = document.querySelectorAll('.toplist-bonus')
const toplistHoverEl = document.querySelectorAll('.vertical-toplist')
const affLink = document.querySelectorAll('.partnerLink')

function handleIntersection(entries) {
  entries.map((entry) => {
    if (entry.isIntersecting) {
      const bonusValueEl = entry.target.querySelector('.toplist-bonus__value')
      // experiment corg21-7070
      const bonusValueElPulseOnly = entry.target.querySelector('.toplist-bonus__value--pulse')

      if (bonusValueElPulseOnly) {
        bonusValueElPulseOnly.classList.add('heartbeat')
      }
      // END experiment corg21-7070
      if (bonusValueEl && parseInt(entry.target.dataset.value)) {
        bonusValueEl.classList.add('heartbeat')

        // eslint-disable-next-line no-new
        const counter = new CountUp(
          bonusValueEl,
          parseInt(entry.target.dataset.value),
          JSON.parse(entry.target.dataset.options),
        )
        counter.start()
      }

      observer.unobserve(entry.target)

      return true
    }

    return false
  })
}

const observer = new IntersectionObserver(handleIntersection)
toplistBonusEls.forEach((el) => observer.observe(el))

// Hover Animation on Partner CTA
function handleIntersectionExperiment(entries) {
  entries.map((entry) => {
    if (entry.isIntersecting) {
      const toplistButton = entry.target.querySelector('.button')

      setTimeout(function () {
        toplistButton.classList.toggle('hover')
      }, 500)

      observer.unobserve(entry.target)

      return true
    }

    return false
  })
}

if (toplistHoverEl) {
  const observerExperiment = new IntersectionObserver(handleIntersectionExperiment)
  affLink.forEach((el) => observerExperiment.observe(el))
}
// END Hover Animation on Partner CTA

// Custom Toplist tracking
const toplistMoreDetails = document.querySelectorAll('.m-toplist-details__more-info__more')
const partnerLink = document.querySelectorAll('.partnerTracking')
const reviewLink = document.querySelectorAll('.reviewTracking')

if (toplistMoreDetails) {
  toplistMoreDetails.forEach((element) => {
    element.addEventListener('click', () => {
      AdobeCustomTracking.methods.addCustomTracking(
        'OToplist::Details',
        'Open',
        'More Details',
        'Dropdown',
        'Click',
        'Toplist',
      )
    })
  })
}

if (partnerLink) {
  partnerLink.forEach((element, index) => {
    element.addEventListener('click', () => {
      AdobeCustomTracking.methods.addCustomTracking(
        `OToplist::PartnerlinkPosition${index + 1}`,
        'Link',
        'Partner',
        'Button',
        'Click',
        'Toplist',
      )
    })
  })
}

if (reviewLink) {
  reviewLink.forEach((element, index) => {
    element.addEventListener('click', () => {
      AdobeCustomTracking.methods.addCustomTracking(
        `OToplist::ReviewlinkPosition${index + 1}`,
        'Link',
        'Reviews',
        'Button',
        'Click',
        'Toplist',
      )
    })
  })
}
// Custom Toplist tracking END

// experiment corg-16669
const userRatingComponent = document.querySelectorAll('.user-rating__stars')

if (userRatingComponent) {
  const isMobile = window.innerWidth < 768

  userRatingComponent.forEach((container, index) => {
    const stars = container.querySelectorAll('.user-rating__star')
    const starsColor = container.parentElement
      .querySelector('.user-rating__stars-color')
      .querySelectorAll('.user-rating__star')
    const storageKey = `userRatingIsClicked_${index}`
    let isClicked = JSON.parse(localStorage.getItem(storageKey)) || false

    // Revert star colors back to original
    const revertStarColors = () => {
      if (!isClicked) {
        stars.forEach((star) => {
          star.classList.remove('blue-star', 'grey-star', 'light-blue-star')
        })
        starsColor.forEach((star) => {
          star.classList.remove('blue-star', 'grey-star', 'light-blue-star')
        })
      }
    }

    // Change star colours on hover (hovered element and all to the left will be blue, original rating will turn light blue)
    const handleMouseover = (hoveredIndex) => {
      stars.forEach((star, i) => {
        if (i > hoveredIndex) {
          star.classList.add('grey-star')
          star.classList.remove('blue-star')
        } else {
          star.classList.remove('grey-star')
          star.classList.add('blue-star')
        }
      })
      starsColor.forEach((star, i) => {
        if (i > hoveredIndex) {
          star.classList.add('light-blue-star')
          star.classList.remove('blue-star')
        } else {
          star.classList.remove('light-blue-star')
          star.classList.add('blue-star')
        }
      })
    }

    // Update partner rating to chosen rating for 8 seconds
    const updateRating = (hoveredIndex) => {
      // get the number from the span below and increment it by 1 and update the span
      const partnerRating = container.closest('.user-rating').querySelector('.user-rating__partner-rating')
      if (partnerRating) {
        const currentRating = parseFloat(partnerRating.textContent)
        if (!isNaN(currentRating)) {
          const updatedNumber = (hoveredIndex + 1).toFixed(1)
          partnerRating.textContent = updatedNumber

          // Revert rating back to original after 8 seconds
          setTimeout(() => {
            partnerRating.textContent = currentRating
          }, 8000)
        }
      }
    }

    // event handlers
    const handleClick = (hoveredIndex) => {
      isClicked = true
      if (isMobile) {
        ;[stars, starsColor].forEach((starsArr) => {
          starsArr.forEach((star, i) => {
            if (i > hoveredIndex) {
              star.classList.add('grey-star')
              star.classList.remove('blue-star')
            } else {
              star.classList.remove('grey-star')
              star.classList.add('blue-star')
            }
          })
        })
      }

      localStorage.setItem(storageKey, JSON.stringify(isClicked))

      // message display
      container.closest('.user-rating').querySelector('.user-rating__thank-you').style.display = 'inline-block'
      container.closest('.user-rating').querySelector('.user-rating__reviews').style.display = 'none'

      updateRating(hoveredIndex)

      container.classList.add('pointer-events-none')

      setTimeout(() => {
        container.closest('.user-rating').querySelector('.user-rating__you-voted').style.display = 'inline-block'
        container.closest('.user-rating').querySelector('.user-rating__thank-you').style.display = 'none'
        // Reset classes on both star collections using spread syntax
        stars.forEach((star) => {
          star.classList.remove('blue-star', 'grey-star')
        })
        starsColor.forEach((star) => {
          star.classList.remove('blue-star', 'grey-star', 'light-blue-star')
        })
      }, 8000)

      AdobeCustomTracking.methods.addCustomTracking(
        `AUserRating::PartnerPosition${index + 1}-Rating${hoveredIndex}`,
        'Link',
        'Reviews',
        'Button',
        'Click',
        'Toplist',
      )
    }

    // Handle initial messages state
    if (isClicked) {
      container.closest('.user-rating').querySelector('.user-rating__you-voted').style.display = 'inline-block'
      container.closest('.user-rating').querySelector('.user-rating__reviews').style.display = 'none'
    }

    // Event listeners
    if (!isClicked) {
      // on mobile we want to start with grey stars, all changes happen on click
      if (isMobile) {
        stars.forEach((star, starIndex) => {
          star.classList.add('grey-star')
          star.addEventListener('click', () => handleClick(starIndex))
        })

        starsColor.forEach((star, starIndex) => {
          star.classList.add('grey-star')
          star.addEventListener('click', () => handleClick(starIndex))
        })
      } else {
        container.addEventListener('mouseleave', revertStarColors)
        stars.forEach((star, starIndex) => {
          star.addEventListener('mouseover', () => handleMouseover(starIndex))
          star.addEventListener('click', () => handleClick(starIndex))
        })
      }
    }
  })
}
// experiment corg-16669 END

// exp corg-17654
if (toplistAnimatedEls) {
  const handleCardIntersection = (entries) => {
    entries.map((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('animate-slide')
        entry.target.style.opacity = '1'

        observer.unobserve(entry.target)

        return true
      }

      return false
    })
  }

  const toplistObserver = new IntersectionObserver(handleCardIntersection, {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  })
  toplistAnimatedEls.forEach((el) => toplistObserver.observe(el))
}

// end

// exp corg-18626, corg-20707, corg-20748
const animatedHeadingElement = document.querySelector('.animated-header-experiment')

if (animatedHeadingElement) {
  const words = ['the best games', 'unbiased reviews', 'winning bonuses', 'the perfect casino']
  let currentWord = ''
  let wordIndex = 0
  let letterIndex = 0
  let backwards = false
  let timeoutId = null

  const typeWord = () => {
    if (!backwards && letterIndex < words[wordIndex].length) {
      currentWord += words[wordIndex][letterIndex]
      letterIndex++
      timeoutId = setTimeout(typeWord, 200)
    } else if (backwards && letterIndex > 0) {
      currentWord = currentWord.slice(0, -1)
      letterIndex--
      timeoutId = setTimeout(typeWord, 100)
    } else if (backwards) {
      backwards = false
      wordIndex = (wordIndex + 1) % words.length
      letterIndex = 0
      currentWord = ''
      typeWord()
    } else {
      setTimeout(() => {
        backwards = true
        typeWord()
      }, 3000)
    }
    animatedHeadingElement.textContent = currentWord
  }

  typeWord()

  window.onbeforeunload = function () {
    clearTimeout(timeoutId)
  }
}
