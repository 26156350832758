import aaConnector from '@netmanagement/aa-connector'
import md5 from 'md5'
import siteConfig from './settings'

export default function ({ route, nuxtState, $config }) {
  // storybook hack
  const excludePaths = ['/iframe.html', 'metrics']
  if (excludePaths.some((item) => route.fullPath.includes(item))) {
    return
  }

  const { content } = nuxtState.data[0] // page and cms data set in template-loader.vue
  const { adobePageTags, adobeToplistData, adobePartnerData, visitor } = content.cms

  const toplistDataIds = adobeToplistData ? [replaceBlankWithPlaceholder(adobeToplistData)] : []
  const partnerDataIds = adobePartnerData ? [replaceBlankWithPlaceholder(adobePartnerData)] : []

  let userInfo = {
    mobile: visitor.device.type === 'mobile',
    tablet: visitor.device.type === 'tablet',
    user_continent: visitor.geo.continent,
    user_country: visitor.geo.countryCode,
    user_state: visitor.geo.stateCode,
    user_city: visitor.geo.city,
  }

  // Use adobePageTags if set, otherwise fallback to empty strings
  let defaultTags = {
    language: adobePageTags?.language ?? '',
    country: adobePageTags?.country ?? '',
    main_category: adobePageTags?.main_category ?? '',
    sub_categories: adobePageTags?.sub_categories ?? '',
    vertical: adobePageTags?.vertical ?? '',
    sub_vertical: adobePageTags?.sub_vertical ?? '',
    tri_vertical: adobePageTags?.tri_vertical ?? '',
    toplist: adobePageTags?.toplist ?? '',
    primarySubCategory: adobePageTags?.primarySubCategory ?? '',
    equivalent_page: adobePageTags?.equivalent_page ? `https://${$config.DOMAIN}${adobePageTags?.equivalent_page}` : '',
    // Phoenix data? http://staging.aaconnector.staging.net.management/what-we-track/#phoenix-integration
    toplist_data_ids: toplistDataIds,
    partner_data_ids: partnerDataIds,
    // we need data for this
    variant: 'corg|nds|1',
    state: '', // get current page "state" tag E.g specific page for a state Ontario, NY etc
    platform: '', // get current page "platform" tag E.g NDS, Legacy, WP
    event: '', // get current page "event" tag E.g sport, poker
    site_section: '', // get current page "siteSection" tag
  }

  window.adobeTags = {
    ...defaultTags,
    ...userInfo,
  }

  return new aaConnector(
    siteConfig($config.ADOBE_LAUNCH_SCRIPT, $config.ADOBE_DEBUG_ENABLED, md5(window.location.host)),
  )
}

const replaceBlankWithPlaceholder = (data) => {
  Object.keys(data).forEach((key) => {
    data[key] = data[key] ? data[key] : 'N/A'
  })

  return data
}
