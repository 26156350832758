import AdobeCustomTracking from '~/helpers/mixins/adobe-custom-tracking'

function setupPopup(trigger, popup, closeButtons) {
  if (trigger) {
    trigger.addEventListener('click', () => {
      popup.style.display = 'flex'
      document.body.style.overflow = 'hidden'
      AdobeCustomTracking.methods.addCustomTracking(
        `OAtfHeroCta::${popup.id === 'experts-pop-up' ? 'Experts' : 'ReviewProcess'}PopUp`,
        `${popup.id === 'experts-pop-up' ? 'Experts' : 'ReviewProcess'} pop up click`,
        null,
        'Button',
        'Click',
        'OAtfHeroCta',
      )
    })
  }

  if (closeButtons.length > 0) {
    Array.from(closeButtons).forEach((button) => {
      button.addEventListener('click', () => {
        popup.style.display = 'none'
        document.body.style.overflow = 'auto'
      })
    })
  }
}

const expertsSpan = document.getElementById('experts-span')
const expertsClose = document.getElementsByClassName('experts-close')
const experts = document.getElementById('experts-pop-up')

const reviewProcessSpan = document.getElementById('review-process-span')
const reviewProcessClose = document.getElementsByClassName('review-process-close')
const reviewProcess = document.getElementById('review-process-pop-up')

setupPopup(expertsSpan, experts, expertsClose)
setupPopup(reviewProcessSpan, reviewProcess, reviewProcessClose)
