// temporary tracking for read more proof of concept (custom tracking event can not be added to hydranteNever components directly)
const readmoreElement = document.querySelectorAll('.a-read-more__hidden')[0]
const readmoreButton = document.querySelectorAll('.a-read-more__handler')[0]

if (readmoreElement && readmoreElement.children.length > 0) {
  readmoreButton.addEventListener('click', function () {
    const readMoreEvent = new CustomEvent('componentInteraction', {
      bubbles: true,
      detail: {
        componentName: 'AReadMore::Click',
        componentOption: 'Read more',
        componentValue: 'Not Applicable',
        componentType: 'Button',
        interactionType: 'Click',
        componentLocation: 'ATF',
      },
    })

    window.dispatchEvent(readMoreEvent)
  })
}
