/***
 * Glue javascript plugin that listens to events from aa-connector, and fowards them to zaraz
 * ----
 * Context: The data team are planning to refactor aa-connector to use a data layer (this will be a new major version) once they do this becomes redundant
 */
export default () => {
  const handleAnalyticsEvent = function (event) {
    if (window.zaraz) {
      window.zaraz.track(event.type)
    }
  }

  window.addEventListener('partnerClickout', handleAnalyticsEvent)
  window.addEventListener('engagementHit', handleAnalyticsEvent)
}
