import AdobeCustomTracking from '~/helpers/mixins/adobe-custom-tracking'

async function onCopy(bonusCodeEl, targetEl) {
  const range = document.createRange()

  try {
    const hiddenClass = 'a-bonus-code-clickable__icon-hidden'

    range.selectNode(bonusCodeEl)
    window.getSelection().removeAllRanges()
    window.getSelection().addRange(range)

    await document.execCommand('copy')
    window.getSelection().removeAllRanges()

    const copyIconEl = targetEl.querySelector('.a-bonus-code-clickable__copy')
    const checkmarkIconEl = targetEl.querySelector('.a-bonus-code-clickable__checkmark')

    copyIconEl.classList.add(hiddenClass)
    checkmarkIconEl.classList.remove(hiddenClass)
    setTimeout(() => {
      copyIconEl.classList.remove(hiddenClass)
      checkmarkIconEl.classList.add(hiddenClass)
    }, 1500)
  } catch (error) {
    console.log('ABonusCodeClickable onCopy error ', error)
    window.getSelection().removeAllRanges()
  }
}

const bonusCodeCopyEl = document.querySelector('.a-bonus-code-clickable')

if (bonusCodeCopyEl) {
  const bonusCodeEl = bonusCodeCopyEl.querySelector('.a-bonus-code-clickable__code')
  const bonusCode = bonusCodeEl?.textContent

  if (bonusCode && bonusCode.toLowerCase() !== 'no code needed') {
    bonusCodeCopyEl.addEventListener('click', () => {
      AdobeCustomTracking.methods.addCustomTracking(
        'MPartnerCardReviewBonus::CopyBonusCode',
        'BonusCode',
        bonusCode,
        'Button',
        'Click',
        'Not Applicable',
      )
      onCopy(bonusCodeEl, bonusCodeCopyEl)
    })
  }
}
