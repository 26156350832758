export const state = () => ({
  arcadeCredentials: null,
  restrictedGeos: ['GB'],
  gamesFiltersAndSorting: null,
})

export const mutations = {
  SET_ARCADE_CREDENTIALS: (state, arcadeCredentials) => {
    state.arcadeCredentials = arcadeCredentials
  },
  SET_GAMES_FILTERS_AND_SORTING: (state, gamesFiltersAndSorting) => {
    state.gamesFiltersAndSorting = gamesFiltersAndSorting
  },
}

export const actions = {
  setArcadeCredentials: ({ commit }, arcadeCredentials) => {
    commit('SET_ARCADE_CREDENTIALS', arcadeCredentials)
  },
  setGamesFiltersAndSorting: async ({ commit }, arcadeFacadeInstance) => {
    const gamesFiltersAndSorting = await arcadeFacadeInstance.getGamesFiltersAndSorting()

    commit('SET_GAMES_FILTERS_AND_SORTING', gamesFiltersAndSorting)
  },
}

export const getters = {
  arcadeCredentials: (state) => {
    return state.arcadeCredentials
  },
  restrictedGeos: (state) => {
    return state.restrictedGeos
  },
  gamesFiltersAndSorting: (state) => {
    return state.gamesFiltersAndSorting
  },
}
