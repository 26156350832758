import smoothscroll from 'smoothscroll-polyfill'
import DeviceAware from '~/helpers/mixins/device-aware'

smoothscroll.polyfill()

document.addEventListener('readystatechange', () => {
  if (document.readyState === 'complete') {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault()
        scrollToAnchor(anchor)
      })
    })
  }
})

// One downside to this, is images that lazyload causes the height to differ on the first run
function scrollToAnchor(anchor) {
  const targetElement = document.querySelector(anchor.getAttribute('href'))

  const yOffset = window.innerWidth >= DeviceAware.data().md_breakpoint ? -120 : -80

  //* Adding section to check for sticky header and adjust scroll accordingly
  const stickyHeader = document.querySelector('.js-scroll-offset')

  const stickyAdj = stickyHeader?.dataset?.offsetAdjust ? parseInt(stickyHeader.dataset.offsetAdjust) : 20

  let stickyOffset = 0

  if (stickyHeader) {
    stickyOffset = stickyHeader.childElementCount > 0 ? stickyHeader.getBoundingClientRect().height + stickyAdj : 0
  }

  const customOffset = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset - stickyOffset

  targetElement.focus()

  // Allow tab index for elements that aren't focusable
  if (document.activeElement !== targetElement) {
    targetElement.setAttribute('tabindex', '-1')
    targetElement.focus()
  }

  window.scrollTo({
    top: customOffset,
    behavior: 'smooth',
  })
}
