export const state = () => ({
  geo: {
    countryCode: null,
    stateCode: null,
    locale: null,
  },
  experimentId: null,
  page: {
    pageUrlFromApi: null,
    currencyFromApi: null,
    vertical: null,
    language: null,
    isEnglish: null,
    isRtl: false,
    gamesPartners: false,
    arcadeRegulation: null,
  },
  gamesLightboxAdditionalPartners: null,
  showMembershipButton: false,
  mobileMenuOpen: false,
  triggerMembershipModal: false,
  isRegulated: false,
  bannerPriorities: new Set(),
  device: {
    type: null,
    os: null,
  },
})

export const mutations = {
  SET_EXPERIMENT_IDS: (state, experimentIds) => {
    state.experimentIds = experimentIds
  },
  SET_COUNTRY_CODE: (state, countryCode) => {
    state.geo.countryCode = countryCode
  },
  SET_STATE_CODE: (state, stateCode) => {
    state.geo.stateCode = stateCode
  },
  SET_IS_REGULATED: (state, isRegulated) => {
    state.isRegulated = isRegulated
  },
  SET_IS_RTL: (state, isRtl) => {
    state.page.isRtl = isRtl
  },
  SET_LOCALE: (state, locale) => {
    state.geo.locale = locale
  },
  SET_URL: (state, url) => {
    state.page.pageUrlFromApi = url
  },
  SET_CURRENCY: (state, currency) => {
    state.page.currencyFromApi = currency
  },
  SET_PAGE_VERTICAL: (state, vertical) => {
    state.page.vertical = vertical
  },
  SET_PAGE_LANGUAGE: (state, language) => {
    state.page.language = language
  },
  SET_PAGE_IS_ENGLISH: (state, isEnglish) => {
    state.page.isEnglish = isEnglish
  },
  SET_GAME_PARTNERS: (state, gamesPartners) => {
    state.page.gamesPartners = gamesPartners
  },
  SET_SHOW_MEMBERSHIP_BUTTON: (state, showMembershipButton) => {
    state.showMembershipButton = showMembershipButton
  },
  SET_MOBILE_MENU_OPEN: (state, mobileMenuOpen) => {
    state.mobileMenuOpen = mobileMenuOpen
  },
  SET_GAMES_LIGHTBOX_ADDITIONAL_PARTNER: (state, gamesLightboxAdditionalPartners) => {
    state.gamesLightboxAdditionalPartners = gamesLightboxAdditionalPartners
  },
  SET_ARCADE_REGULATION: (state, arcadeRegulation) => {
    state.page.arcadeRegulation = arcadeRegulation
  },
  SET_TRIGGER_MEMBERSHIP_MODAL: (state, triggerMembershipModal) => {
    state.triggerMembershipModal = triggerMembershipModal
  },
  SET_BANNER_PRIORITIES: (state, bannerPriorities) => {
    state.bannerPriorities.add(bannerPriorities)
  },
  SET_OS: (state, os) => {
    state.device.os = os
  },
  SET_DEVICE: (state, type) => {
    state.device.type = type
  },
}

export const actions = {
  nuxtServerInit: ({ dispatch }) => {
    dispatch('arcade/setArcadeCredentials', [
      process.env.GAMES_CLIENT_ID,
      process.env.GAMES_CLIENT_SECRET,
      process.env.GAMES_HOST,
      process.env.GAMES_API_PORT,
      process.env.GAMES_API_URL,
    ])
  },
  setExperimentIds: ({ commit }, experimentIds) => {
    commit('SET_EXPERIMENT_IDS', experimentIds)
  },
  setCountryCode: ({ commit }, countryCode) => {
    commit('SET_COUNTRY_CODE', countryCode)
  },
  setStateCode: ({ commit }, stateCode) => {
    commit('SET_STATE_CODE', stateCode)
  },
  setIsRegulated: ({ commit }, isRegulated) => {
    commit('SET_IS_REGULATED', isRegulated)
  },
  setisRtl: ({ commit }, isRtl) => {
    commit('SET_IS_RTL', isRtl)
  },
  setGamesPartners: ({ commit }, gamesPartners) => {
    commit('SET_GAME_PARTNERS', gamesPartners)
  },
  setLocale: ({ commit }, stateCode) => {
    commit('SET_LOCALE', stateCode)
  },
  setPageUrlFromApi: ({ commit }, url) => {
    commit('SET_URL', url)
  },
  setCurrencyFromApi: ({ commit }, currency) => {
    commit('SET_CURRENCY', currency)
  },
  setPageVertical: ({ commit }, vertical) => {
    commit('SET_PAGE_VERTICAL', vertical)
  },
  setPageLanguage: ({ commit }, language) => {
    commit('SET_PAGE_LANGUAGE', language)
  },
  setPageIsEnglish: ({ commit }, isEnglish) => {
    commit('SET_PAGE_IS_ENGLISH', isEnglish)
  },
  setShowMembershipButton: ({ commit }, showMembershipButton) => {
    commit('SET_SHOW_MEMBERSHIP_BUTTON', showMembershipButton)
  },
  setMobileMenuOpen: ({ commit }, mobileMenuOpen) => {
    commit('SET_MOBILE_MENU_OPEN', mobileMenuOpen)
  },
  setTriggerMembershipModal: ({ commit }, triggerMembershipModal) => {
    commit('SET_GAMES_LIGHTBOX_ADDITIONAL_PARTNER', triggerMembershipModal)
  },
  setGamesLightboxAdditionalPartners: ({ commit }, gamesLightboxAdditionalPartners) => {
    commit('SET_GAMES_LIGHTBOX_ADDITIONAL_PARTNER', gamesLightboxAdditionalPartners)
  },
  setArcadeRegulation: ({ commit }, arcadeRegulation) => {
    commit('SET_ARCADE_REGULATION', arcadeRegulation)
  },
  setBannerPriorities: ({ commit }, bannerPriorities) => {
    commit('SET_BANNER_PRIORITIES', bannerPriorities)
  },
  setOs: ({ commit }, os) => {
    commit('SET_OS', os)
  },
  setDevice: ({ commit }, device) => {
    commit('SET_DEVICE', device)
  },
}

export const getters = {
  countryCode: (state) => {
    return state.geo.countryCode
  },

  stateCode: (state) => {
    return state.geo.stateCode
  },

  isRegulated: (state) => {
    return state.isRegulated
  },

  isRtl: (state) => {
    return state.page.isRtl
  },

  gamesPartners: (state) => {
    return state.page.gamesPartners
  },

  locale: (state) => {
    return state.geo.locale.substring(0, 2)
  },

  pageUrlFromApi: (state) => {
    return state.page.pageUrlFromApi
  },

  currencyFromApi: (state) => {
    return state.page.currencyFromApi
  },

  pageVertical: (state) => {
    return state.page.vertical
  },

  pageLanguage: (state) => {
    return state.page.language
  },

  pageIsEnglish: (state) => {
    return state.page.isEnglish
  },

  showMembershipButton: (state) => {
    return state.showMembershipButton
  },

  mobileMenuOpen: (state) => {
    return state.mobileMenuOpen
  },

  gamesLightboxAdditionalPartners: (state) => {
    return state.gamesLightboxAdditionalPartners
  },

  arcadeRegulation: (state) => {
    return state.page.arcadeRegulation
  },

  triggerMembershipModal: (state) => {
    return state.triggerMembershipModal
  },

  bannerPriorities: (state) => {
    return state.bannerPriorities
  },
  os: (state) => {
    return state.device.os
  },
  device: (state) => {
    return state.device.type
  },
}
