import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _eb9d8a4c = () => interopDefault(import('../pages/metrics.vue' /* webpackChunkName: "pages/metrics" */))
const _7e4f28e2 = () => interopDefault(import('../pages/membership/email-confirm.vue' /* webpackChunkName: "pages/membership/email-confirm" */))
const _299168c0 = () => interopDefault(import('../pages/membership/email-unsubscribe.vue' /* webpackChunkName: "pages/membership/email-unsubscribe" */))
const _1b3f65cd = () => interopDefault(import('../pages/membership/password-reset.vue' /* webpackChunkName: "pages/membership/password-reset" */))
const _af8070c0 = () => interopDefault(import('../pages/membership/email-unsubscribe.vue' /* webpackChunkName: "" */))
const _6d7ee78f = () => interopDefault(import('../pages/membership/email-confirm.vue' /* webpackChunkName: "" */))
const _036869cd = () => interopDefault(import('../pages/membership/password-reset.vue' /* webpackChunkName: "" */))
const _ac54d6c0 = () => interopDefault(import('../components/templates/membership-template-loader.vue' /* webpackChunkName: "" */))
const _9f819576 = () => interopDefault(import('../components/templates/template-loader.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/metrics",
    component: _eb9d8a4c,
    name: "metrics"
  }, {
    path: "/membership/email-confirm",
    component: _7e4f28e2,
    name: "membership-email-confirm"
  }, {
    path: "/membership/email-unsubscribe",
    component: _299168c0,
    name: "membership-email-unsubscribe"
  }, {
    path: "/membership/password-reset",
    component: _1b3f65cd,
    name: "membership-password-reset"
  }, {
    path: "/membership/unsubscribe/:userId/:userEmailToken",
    component: _af8070c0,
    props: true,
    name: "membershipEmailUnsubscribe"
  }, {
    path: "/membership/email-confirm/:userId/:codeParameter",
    component: _6d7ee78f,
    props: true,
    name: "membershipEmailConfirm"
  }, {
    path: "/membership/password-reset/:userId/:codeParameter",
    component: _036869cd,
    props: true,
    name: "membershipPasswordReset"
  }, {
    path: "/membership/:path+",
    component: _ac54d6c0,
    name: "membershipBase"
  }, {
    path: "*",
    component: _9f819576,
    name: "base"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
