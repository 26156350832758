export default {
  data() {
    return {
      clientHeight: 0,
      clientWidth: 0,
      isPortrait: false,
      lg_breakpoint: 1024,
      lg: false,
      md_breakpoint: 768,
      md: false,
      xl_breakpoint: 1280,
      xl: false,
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.setClientWidth()
      this.setClientHeight()
      this.setOrientation()
      this.md = window.innerWidth >= this.md_breakpoint
      this.lg = window.innerWidth >= this.lg_breakpoint
      this.xl = window.innerWidth >= this.xl_breakpoint
    },
    setClientWidth() {
      this.clientWidth = window.innerWidth
    },
    setClientHeight() {
      this.clientHeight = window.innerHeight
    },
    setOrientation() {
      this.isPortrait = this.clientWidth < this.clientHeight
    },
  },
}
