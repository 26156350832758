export default () => {
  // eslint-disable-next-line no-unused-expressions
  import('lazysizes')
  import('lazysizes/plugins/attrchange/ls.attrchange')

  document.addEventListener('lazybeforeunveil', function (e) {
    const bg = e.target.getAttribute('data-bg')
    if (bg) {
      e.target.style.backgroundImage = 'url(' + bg + ')'
    }
  })
}
