const state = () => ({
  formState: {
    signUp: {
      step: 0,
    },
  },
  loggedIn: false,
  logout: null,
  userValidityChecked: false,
  userValidationError: null,
  lastResendCodeTimestamp: 0,
  userData: null,
})

const actions = {
  setStep({ commit }, { formId, step }) {
    commit('SET_STEP', { formId, step })
  },
  initialiseFormState({ commit }, formId) {
    commit('INIT_FORM_STATE', formId)
  },
  setLoggedInState({ commit }, value) {
    commit('SET_LOGGED_IN_STATE', value)
  },
  setLogoutState({ commit }, value) {
    commit('SET_LOGOUT_STATE', value)
  },
  setUserValidityCheckedState({ commit }) {
    commit('SET_USER_VALIDITY_CHECKED_STATE')
  },
  setUserValidationErrorState({ commit }, value) {
    commit('SET_USER_VALIDATION_ERROR_STATE', value)
  },
  setLastResendCodeTimestamp({ commit }, value) {
    commit('SET_LAST_RESEND_CODE_TIMESTAMP', value)
  },
  setUserData({ commit }, value) {
    commit('SET_USER_DATA', value)
  },
}

const mutations = {
  initialiseStore(state) {
    if (typeof window !== 'undefined') {
      const timestamp = localStorage.getItem('membership.lastResendCodeTimestamp') || null
      if (timestamp) {
        if (!isNaN(timestamp)) {
          state.lastResendCodeTimestamp = timestamp
        } else {
          localStorage.removeItem('membership.lastResendCodeTimestamp')
        }
      }
    }
  },
  SET_STEP(state, payload) {
    const { formId, step } = payload
    state.formState[formId].step = step
  },
  INIT_FORM_STATE(state, formId) {
    state.formState[formId] = {
      step: 0,
    }
  },
  SET_LOGGED_IN_STATE(state, payload) {
    state.loggedIn = payload
  },
  SET_LOGOUT_STATE(state, payload) {
    state.logout = payload
  },
  SET_USER_VALIDITY_CHECKED_STATE(state) {
    state.userValidityChecked = true
  },
  SET_USER_VALIDATION_ERROR_STATE(state, payload) {
    state.userValidationError = payload
  },
  SET_LAST_RESEND_CODE_TIMESTAMP(state, payload) {
    state.lastResendCodeTimestamp = payload
    localStorage.setItem('membership.lastResendCodeTimestamp', JSON.stringify(payload))
  },
  SET_USER_DATA(state, payload) {
    state.userData = payload
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
