export default {
  methods: {
    addCustomTracking(
      componentName,
      componentOption,
      componentValue,
      componentType,
      interactionType,
      componentLocation,
    ) {
      const componentEvent = new CustomEvent('componentInteraction', {
        bubbles: true,
        detail: {
          componentName,
          componentOption,
          componentValue,
          componentType,
          interactionType,
          componentLocation,
        },
      })

      window.dispatchEvent(componentEvent)
    },

    membershipLoginTracking(memberId, loginStatus) {
      const loginEvent = new CustomEvent('loginEvent', {
        bubbles: true,
        detail: {
          memberId,
          memberJoinDate: new Date(),
          memberStatus: '',
          loginSuccessful: loginStatus,
          loginMethod: 'Standard:Email',
        },
      })

      window.dispatchEvent(loginEvent)
    },

    membershipRegistrationTracking(memberId, emailOptedIn, registrationStatus) {
      const registrationEvent = new CustomEvent('registrationEvent', {
        bubbles: true,
        detail: {
          emailOptedIn,
          memberId,
          memberJoinDate: new Date(),
          memberStatus: '',
          registrationSuccessful: registrationStatus,
          registrationMethod: 'Standard:Email',
        },
      })

      window.dispatchEvent(registrationEvent)
    },
  },
}
