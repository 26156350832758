import AdobeCustomTracking from '~/helpers/mixins/adobe-custom-tracking'

const authorTooltip = document.getElementById('author-tooltip')
if (authorTooltip) {
  const author = document.querySelector('.a-author__image-container')

  const authorElems = [author, ...author.children, authorTooltip, ...authorTooltip.children]

  const authorNameElem = document.querySelector('.a-author__name')

  const toggleTooltip = (tooltip, isShow) => {
    if (!tooltip) return
    if (isShow) {
      tooltip.classList.add('active')
    } else {
      tooltip.classList.remove('active')
    }
  }
  authorElems.forEach((elem) => {
    elem.addEventListener('mouseover', () => {
      toggleTooltip(authorTooltip, true)
    })
  })

  author.addEventListener('touchstart', () => {
    AdobeCustomTracking.methods.addCustomTracking(
      'AAuthor::AuthorIcon',
      'Author Icon Tap',
      null,
      'Icon',
      'Click',
      'Not Applicable',
    )
  })

  authorElems.forEach((elem) => {
    elem.addEventListener('mouseleave', () => {
      toggleTooltip(authorTooltip, false)
    })
  })

  authorNameElem.addEventListener('click', () => {
    toggleTooltip(authorTooltip, true)
  })

  document.addEventListener('click', (e) => {
    if (![...authorElems, authorNameElem].includes(e.target)) {
      toggleTooltip(authorTooltip, false)
    }
  })
}
