import Vue from 'vue'
import * as Sentry from '@sentry/browser'

export const translations = (words) => {
  Vue.$words = words

  Vue.mixin({
    methods: {
      lang(key) {
        if (this.hasLang(key) === false) {
          this.sentryLog(key)
          return ''
        }

        return Vue.$words[key]
      },
      hasLang(key) {
        return Vue.$words[key] !== undefined
      },
      sentryLog(key) {
        try {
          Sentry.captureException(`Translation [${key}] is missing`)
        } catch (error) {}
      },
    },
  })
}

export default (context, inject) => {
  inject('translations', translations)
}
