export const state = () => ({
  adobeToplistData: [],
  adobePartnerData: [],
})

export const mutations = {
  SET_TOPLIST_DATA: (state, adobeToplistData) => {
    state.adobeToplistData = adobeToplistData
  },
  SET_PARTNER_DATA: (state, adobePartnerData) => {
    state.adobePartnerData = adobePartnerData
  },
}

export const actions = {
  setAdobeToplistData: ({ commit }, adobeToplistData) => {
    commit('SET_TOPLIST_DATA', adobeToplistData)
  },
  setAdobePartnerData: ({ commit }, adobePartnerData) => {
    commit('SET_PARTNER_DATA', adobePartnerData)
  },
}

export const getters = {
  adobeToplistData: (state) => {
    return state.adobeToplistData
  },
  adobePartnerData: (state) => {
    return state.adobePartnerData
  },
}
