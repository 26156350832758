export default function ({ req, redirect }) {
  if (req && req.url && req.url.includes('search')) {
    const url = req.url.replace(/%2F/g, '/').replace(/%3A/g, ':')
    const baseURLTrailingSlash = url.match(/.https:\/\/www\.casino\.org+(.+\/).*?$/)
    const baseURLNoSlash = url.match(/(www\.casino\.org)/)

    if (baseURLTrailingSlash) {
      redirect(301, baseURLTrailingSlash[1])
    } else if (baseURLNoSlash) {
      redirect(301, '/')
    }
  }
}
